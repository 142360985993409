<template>
   <div class="col-12" ref="command">
      <div class="card mb-2">
         <div class="card-body px-3 p-y-10">
            <div class="row m-0 my-1 align-items-center">
               <div class="col">
                  <h6 class="limitador my-0">/{{ command.tipo }}</h6>
               </div>
               <div class="w-max-content">
                  <label class="switch">
                     <input type="checkbox" v-model="command.enabled" @click="toggleMessage"><span class="slider"></span>
                  </label>
               </div>
               <div class="w-max-content pl-1">
                  <button class="btn btn-primary mr-1" @click="editMessage"><i class="far fa-pen font-12 mr-1"></i>{{ $t("customCommands.editMessage") }}</button>
                  <button class="btn btn-danger" @click="remove(command.id)"><i class="far fa-trash"></i></button>
               </div>
            </div>
         </div>
      </div>
      <PreviewMessageDeck class="m-0 p-0" v-show="command.enabled" :message="command" :dadosUsuario="dadosUsuario" />
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
	name: 'Command',
   props: ['command'],
   computed: {
		...mapState({
         dadosUsuario: state => state.dadosUsuario,
         serverS: state => state.serverS,
			urlRest: state => state.urlRest
		}),
	},
   components: {
      PreviewMessageDeck
   },
   methods: {
      editMessage : function () {
         this.$emit('editMessage', this.command)
      },
      toggleMessage : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.command.enabled = !this.command.enabled

         this.$axios({
            method: 'post',
            url: this.urlRest + 'servicos/toggleMessageCustomCommand',
            params: {
               idServer: this.serverS.id_s,
               type: this.command.tipo,
               value: this.command.enabled
            }
         }).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.message")
            })
         }).catch((error) => {
            this.command.enabled = !this.command.enabled

            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      remove : function (type) {
         Swal.fire({
            icon: 'warning',
            title: this.$t("serverSettings.swal.delCmd"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'get',
                  url: this.urlRest + 'servicos/deletarCommand',
                  params: {
                     idServer: this.serverS.id_s,
                     command: type
                  }
               }).then(() => {
                  this.$refs.command.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.commandDeleted")
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Error: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   }
}

</script>

<style scoped>

img {
   width: 120px;
   height: 120px;
}

</style>