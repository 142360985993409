<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body text-right p-y-10">
               <button class="btn btn-default" @click="openAddCommand"><i class="far fa-plus font-13 mr-1"></i> {{ $t('customCommands.buttonAdd') }}</button>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="row row-cols-xl-3">
            <command v-for="(command, index) in listCommands" :key="index" :command="command" @editMessage="editPreview($event)" />
         </div>
      </div>

      <!-- modalAddCommand -->
      <div class="modal fade" id="modalAddCommand" tabindex="-1" aria-labelledby="modalAddCommandLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalAddCommandLabel">{{ $t('customCommands.addCommand.title') }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-0">
                     <div class="card-body">
                        <label><i class="far fa-code color-theme font-13 mr-1"></i>{{ $t('customCommands.addCommand.subtitle') }}</label>
                        <textarea class="form-control" v-model="commandValue" @keyup.enter="saveCommand"></textarea>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveCommand">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex';
import MessageDeck from '@/components/MessageDeck.vue'
import command from '@/components/command/Command.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
   name: 'General_Commands',
   data: function () {
      return {
         commandValue: "",
         listCommands: [],
         message: null
      }
   },
   computed: {
      ...mapState({
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
         serverS: state => state.serverS,
         urlRest: state => state.urlRest,
      }),
   },
   components: {
      PreviewMessageDeck, MessageDeck, command
   },
   methods: {
      getCommands: function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.listCommands = []

         this.$axios({
            method: 'get',
            url: this.urlRest + 'servicos/getCommands',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.listCommands = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      openAddCommand: function () {
         $('#modalAddCommand').modal('show')
      },
      attObjetoMessage: function (e) {
         this.message = e;
      },
      editPreview: function (preview) {
         this.message = null;

         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      savePreview: function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getCommands()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      saveCommand: function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest + 'servicos/criarCommand',
            params: {
               idServer: this.serverS.id_s,
               command: String(this.commandValue).replace(/\s+/g, '_')
            }
         }).then(response => {
            this.listCommands = []
            $('#modalAddCommand').modal('hide')

            this.commandValue = ""
            setTimeout(() => this.listCommands = response.data, 250);

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      this.getCommands()
   }
}

</script>